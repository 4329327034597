<template>
  <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }"
              >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{path: '/raceManagement/ibcHomeManagement'}">IBC赛务管理</el-breadcrumb-item>
          <el-breadcrumb-item>考场管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 筛选 -->
      <el-card style="margin-top: 15px">
          <el-row class="title_row">
              <el-col>
                  <span>考场信息筛选</span>
              </el-col>
          </el-row>
          <el-form
              :model="queryForm"
              class="filter_Form"
              label-width="80px"
              ref="queryFormRef"
          >
              <el-row :gutter="50">
                  <el-col :span="6">
                      <el-form-item label="届" prop="ibcSession">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.ibcSession"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_ibcSession"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="季度" prop="ibcSeason">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.ibcSeason"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_ibcSeason"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="轮次" prop="ibcRound">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.ibcRound"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_ibcRound"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="组别" prop="hallLevel">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.hallLevel"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_sspcnGroup"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row :gutter="50">
                  <el-col :span="6">
                      <el-form-item label="房间类型" prop="roomStatus">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.roomStatus"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_roomStatus"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="比赛类型" prop="matchType">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.matchType"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_matchType"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
              </el-row>
          </el-form>
          <el-row :gutter="20" type="flex" justify="end">
              <el-col :span="2">
                  <el-button
                      type="primary"
                      style="width: 100%"
                      @click="getDataList"
                      >查询</el-button
                  >
              </el-col>
              <el-col :span="2">
                  <el-button type="primary" style="width: 100%" @click="reset"
                      >重置</el-button
                  >
              </el-col>
          </el-row>
      </el-card>
      <!-- 考场信息列表 -->
      <el-card style="margin-top: 15px">
          <el-row class="title_row" align="middle" type="flex">
              <el-col :span="5">
                  <span
                      style="
                          display: -moz-inline-box;
                          display: inline-block;
                          width: 120px;
                      "
                      >考场信息列表</span
                  >
                  <span style="color: blue; font-size: 14px">{{
                      "已录入" + total + "个考场"
                  }}</span>
              </el-col>
              <el-col :span="19" style="text-align: right;">
                  <div class="div_algin_right">
                      <el-button
                          type="primary"
                          @click="addExamRoomData"
                          >新增考场</el-button
                      >
                  </div>
              </el-col>
          </el-row>
          <el-table
              header-cell-class-name="tableHeaderStyle"
              :data="dataList"
              border
              stripe
          >
              <el-table-column label="#" type="index"></el-table-column>
              <el-table-column label="考场名称" prop="hallName">
                  <template slot-scope="scope">
                      <el-button
                          type="text"
                          size="small"
                          @click="examinationDetailClicked(scope.row)"
                          >{{ scope.row.hallName }}</el-button
                      >
                  </template>
              </el-table-column>
              <el-table-column
                  label="届"
                  prop="ibcSession"
                  :formatter="sessionFormatter"
              >
              </el-table-column>
              <el-table-column
                  label="赛季"
                  prop="ibcSeason"
                  :formatter="seasonFormatter"
              >
              </el-table-column>
              <el-table-column
                  label="轮次"
                  prop="ibcRound"
                  :formatter="roundFormatter"
              >
              </el-table-column>
              <el-table-column
                  label="组别"
                  prop="hallLevel"
                  :formatter="groupFormatter"
              ></el-table-column>
              <el-table-column
                  label="比赛类型"
                  prop="matchType"
                  :formatter="matchTypeFormatter"
              >
            </el-table-column>
              <el-table-column label="开始时间" prop="startTime">
              </el-table-column>
              <el-table-column
                  label="结束时间"
                  prop="endTime"
              ></el-table-column>
              <el-table-column
                  label="会议号"
                  prop="meetingNum"
              ></el-table-column>
              <el-table-column label="人数" prop="roomNum"></el-table-column>
              <el-table-column label="房间类型" prop="roomStatusStr">
                  <template slot-scope="scope">
                      <el-tag type="success" v-if="scope.row.roomStatusStr === '进行中'"
                          >{{scope.row.roomStatusStr}}</el-tag
                      >
                      <el-tag type="danger" v-else-if="scope.row.roomStatusStr === '已结束'"
                          >{{scope.row.roomStatusStr}}</el-tag
                      >
                      <el-tag type="warning" v-else
                          >{{scope.row.roomStatusStr}}</el-tag
                      >
                  </template>
              </el-table-column>
              <el-table-column label="操作" width="120px">
                  <template slot-scope="scope">
                      <el-button
                          type="text"
                          icon="el-icon-edit"
                          size="small"
                          @click="showEditDialog(scope.row)"
                          >修改</el-button
                      >
                      <el-button
                          type="text"
                          icon="el-icon-delete"
                          size="small"
                          @click="delecteEditDialog(scope.row)"
                          >删除</el-button
                      >
                  </template>
              </el-table-column>
          </el-table>
      </el-card>
      <!-- 编辑信息的dialog -->
      <el-dialog
          title="编辑考场信息"
          :visible.sync="editInfoDialogVisible"
          width="40%"
          @close="editInfoDialogClose"
      >
          <el-form
              :model="editInfoForm"
              label-width="150px"
              ref="editInfoFormRef"
              :rules="editInfoRules"
          >
              <el-form-item label="届" prop="ibcSession" style="width: 80%">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      :disabled="editRoomData"
                      v-model="editInfoForm.ibcSession"
                  >
                      <el-option
                          v-for="item in dict_ibcSession"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="赛季" prop="ibcSeason" style="width: 80%">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      :disabled="editRoomData"
                      v-model="editInfoForm.ibcSeason"
                  >
                      <el-option
                          v-for="item in dict_ibcSeason"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="轮次" prop="ibcRound" style="width: 80%">
                  <el-select
                      placeholder="请选择"
                      :disabled="editRoomData"
                      @change="editRoomRoundChange"
                      style="width: 100%"
                      v-model="editInfoForm.ibcRound"
                  >
                      <el-option
                          v-for="item in dict_ibcRound"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="比赛类型" prop="matchType" style="width: 80%">
                  <el-select
                      placeholder="请选择"
                      :disabled="true"
                      style="width: 100%"
                      v-model="editInfoForm.matchType"
                  >
                      <el-option
                          v-for="item in dict_matchType"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="组别" prop="hallLevel" style="width: 80%" v-if="editInfoForm.ibcRound !== '5'">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editInfoForm.hallLevel"
                  >
                      <el-option
                          v-for="item in dict_sspcnGroup"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item
                  label="会议号"
                  prop="meetingNum"
                  style="width: 80%"
              >
                  <el-input
                      v-model="editInfoForm.meetingNum"
                      placeholder="请输入"
                      maxlength="20"
                      clearable
                  ></el-input>
              </el-form-item>
              <div style="color: red; text-align: center;">注*比赛时间为未开始时，会在小程序端展示此会议号</div>
              <el-form-item
                  label="比赛开始时间"
                  prop="startTime"
                  style="width: 80%"
              >
                  <el-date-picker
                      v-model="editInfoForm.startTime"
                      :picker-options="pickerOptions"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
              </el-form-item>
              <el-form-item
                  label="比赛结束时间"
                  prop="endTime"
                  style="width: 80%"
              >
                  <el-date-picker
                      v-model="editInfoForm.endTime"
                      :picker-options="pickerOptions"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="editInfoDialogVisible = false"
                  >取 消</el-button
              >
              <el-button type="primary" @click="editInfo">保 存</el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
import { getIbcRoomList, updateIbcRoom, deleteIbcRoom, queryIbcSeason } from '@/http/api'
export default {
  data () {
    return {
      editRoomData: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      total: 0,
      dataList: [],
      editInfoDialogVisible: false,
      dict_roomStatus: [{ dictCode: 0, dictValue: 0, dictLabel: '未开始' }, { dictCode: 1, dictValue: 1, dictLabel: '进行中' }, { dictCode: 2, dictValue: 2, dictLabel: '已结束' }],
      queryForm: {
        roomStatus: '',
        hallLevel: null,
        ibcSession: this.$ibcManager.ibcSession,
        ibcSeason: this.$ibcManager.ibcSeason,
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcRound: '',
        matchType: null,
        meetingNum: '',
        startTime: '',
        endTime: ''
      },
      editInfoForm: {
        ibcSession: this.$ibcManager.ibcSession,
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcSeason: this.$ibcManager.ibcSeason,
        ibcRound: '',
        hallLevel: null,
        meetingNum: '',
        startTime: '',
        matchType: null,
        endTime: ''
      },
      editInfoRules: {
        ibcSession: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcSeason: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcRound: [{ required: true, message: '请选择', trigger: 'change' }],
        hallLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        meetingNum: [{ required: true, message: '请输入', trigger: 'blur' }],
        matchType: [{ required: true, message: '请选择', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择比赛开始时间', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择比赛结束时间', trigger: 'change' }]
      },
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      dict_matchType: this.$userInfo.getDataList(this, 'ibc_matchType').then(value => { this.dict_matchType = value })
    }
  },
  created () {
    this.getQueryIbcSeason()
  },
  methods: {
    getQueryIbcSeason () {
      queryIbcSeason().then((res) => {
        const newObj = Object.entries(res.data).reduce((obj, [key, value]) => {
          obj[key] = value.toString()
          return obj
        }, {})
        console.log('newObj', newObj)
        this.queryForm = { ...this.queryForm, ...newObj }
        this.editInfoForm = { ...this.editInfoForm, ...newObj }
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    sessionFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcSession) {
        if (Object.hasOwnProperty.call(this.dict_ibcSession, key)) {
          const element = this.dict_ibcSession[key]
          if (element.dictValue === data.ibcSession + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    seasonFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcSeason) {
        if (Object.hasOwnProperty.call(this.dict_ibcSeason, key)) {
          const element = this.dict_ibcSeason[key]
          if (element.dictValue === data.ibcSeason + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    roundFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcRound) {
        if (Object.hasOwnProperty.call(this.dict_ibcRound, key)) {
          const element = this.dict_ibcRound[key]
          if (element.dictValue === data.ibcRound + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      console.log('data', data)
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === data.hallLevel) {
            temp = element.dictLabel
          }
        }
      }
      if (data.ibcRound === 5) {
        temp = '无组别'
      }
      return temp
    },
    matchTypeFormatter (data) {
      console.log('data', data)
      var temp = '未设置'
      for (const key in this.dict_matchType) {
        if (Object.hasOwnProperty.call(this.dict_matchType, key)) {
          const element = this.dict_matchType[key]
          if (element.dictValue === data.matchType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 获取考场列表
    getDataList () {
      getIbcRoomList(this.queryForm).then((res) => {
        this.dataList = res.data
        this.total = res.data.length
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 重置筛选条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    // 删除考场弹窗提示
    delecteEditDialog (rowData) {
      this.$confirm('是否删除该考场？考场删除后，该考场考生需要重新添加。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delecteGameRoom(rowData)
      }).catch(() => { })
    },
    // 确认删除考场
    async delecteGameRoom (rowData) {
      rowData.activityType = this.$chnEngStatusCode.ibcActivityType
      deleteIbcRoom(rowData).then((res) => {
        this.$message.success('删除成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 添加考场弹窗关闭
    editInfoDialogClose () {
      this.$refs.editInfoFormRef.resetFields()
    },
    // 选择考场轮次
    editRoomRoundChange (val) {
      console.log('val', val)
      if (val === '2') {
        this.editInfoForm.matchType = '2'
      } else if (val === '3') {
        this.editInfoForm.matchType = '14'
      } else if (val === '4') {
        this.editInfoForm.matchType = '16'
      } else if (val === '5') {
        delete this.editInfoForm.hallLevel
        this.editInfoForm.matchType = '17'
      }
    },
    // 确认新增考场点击事件
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        if (!this.checkTimeRule()) {
          return this.$message.warning('比赛开始时间不得大于或等于结束时间')
        }
        this.editInfoForm.activityType = this.$chnEngStatusCode.ibcActivityType
        updateIbcRoom(this.editInfoForm).then((res) => {
          this.$message.success('操作成功')
          this.editInfoDialogVisible = false
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    addExamRoomData () {
      this.editRoomData = false
      this.editInfoForm = {
        ibcSession: this.queryForm.ibcSession,
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcSeason: this.queryForm.ibcSeason,
        ibcRound: '',
        hallLevel: null,
        meetingNum: '',
        startTime: '',
        matchType: null,
        endTime: ''
      }
      this.editInfoDialogVisible = true
    },
    showEditDialog (rowData) {
      console.log('rowData', rowData)
      this.editRoomData = true
      this.editInfoForm = JSON.parse(JSON.stringify(rowData))
      this.editInfoForm.ibcSession = this.editInfoForm.ibcSession + ''
      this.editInfoForm.ibcSeason = this.editInfoForm.ibcSeason + ''
      this.editInfoForm.ibcRound = this.editInfoForm.ibcRound + ''
      this.editInfoForm.matchType = this.editInfoForm.matchType + ''
      console.log('this.editInfoForm ', this.editInfoForm)
      this.editInfoDialogVisible = true
    },
    examinationDetailClicked (row) {
      this.$router.push(
        {
          path: '/raceManagement/ibc/ibcExamination_detail',
          query: {
            roomData: JSON.stringify(row)
          }
        }
      )
    },
    // 时间选择规则
    checkTimeRule () {
      if (this.editInfoForm.startTime > this.editInfoForm.endTime || this.editInfoForm.startTime === this.editInfoForm.endTime) {
        return false
      }
      return true
    }
  }
}
</script>

<style>
</style>
